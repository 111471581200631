import { Input } from '@repo/ui/components/Input.js'
import { cn } from '@repo/ui/utils/utils'
import {
  type ComponentProps,
  useDeferredValue,
  useEffect,
  useState
} from 'react'
import { useTableContext } from './useTableContext'

export const DataTableSearch = ({
  placeholder = 'Search...',
  className,
  ...props
}: {
  placeholder?: string
} & ComponentProps<typeof Input>) => {
  const [mounted, setMounted] = useState(false)

  const {
    control: { setQuery },
    params
  } = useTableContext()

  const [value, setValue] = useState(() => params.query || '')

  const deferredValue = useDeferredValue(value)

  useEffect(() => {
    setMounted(true)
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  useEffect(() => {
    if (!mounted) return
    setQuery(deferredValue)
  }, [deferredValue, setQuery])

  return (
    <Input
      {...props}
      className={cn(className)}
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      type="search"
      icon="search"
      placeholder={placeholder}
    />
  )
}
